import "./App.css";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Download } from "./routes/Download";
import { PageNotFound } from "./routes/PageNotFound";
import { Upload } from "./routes/Upload";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="w-full p-8 flex justify-center">
      <Routes>
        <Route path="/" element={<Upload />} />
        <Route path="/d/:fileId" element={<Download />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
