import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { supabase} from '../supabase-config';
import { bucketName, s3 } from '../storJ-config';


const Upload = () => {
    const [provider, setProvider] = useState("anon-files"); // anon-files, storj
    const [fileId, setFileId] = useState("");
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [fileSize, setFileSize] = useState(0);
    
      const anonFile_BG = provider === 'anon-files' ? "bg-slate-50" : "";
      const storJ_BG = provider === 'storj' ? "bg-slate-50" : "";
    

    const handleFileChange = (e) => {
        let rawFile = e.target.files[0];
        setFileId(uuidv4().split('-').join(''));
        setFile(rawFile);
        setFileName(rawFile.name);
        setFileType(rawFile.type);
        setFileSize(rawFile.size);
    }

    const handleUpload = async (e) => {
        const params = {
            Bucket: bucketName,
            Key: fileId,
            Body: file
        };

        try {
            let res = await s3.upload(params, {
                partSize: 64 * 1024 * 1024
            }).promise();

            console.log(res);
            console.log("Successfully uploaded file");

            const { data, error } = await supabase
                .from('anon-files')
                .insert([
                    {
                        name: fileName,
                        size: fileSize,
                        type: fileType,
                        s3_key: fileId,
                    },
                ])
                .select()

            console.log(data, error)
        } catch (err) {
            console.error(err);
        }
    }

    const handleDelete = (e) => { }

    const handleLog = async (e) => {
        console.log(fileId);
        console.log(file);
        console.log(fileName);
        console.log(fileType);
        console.log(fileSize);

        const { data, error } = await supabase
            .from('anon-files')
            .insert([
                {
                    name: fileName,
                    size: fileSize,
                    type: fileType,
                    s3_key: fileId,
                },
            ])
            .select()

        console.log(data, error)
    }

    return (
        <div className='w-full md:w-[600px] rounded-lg bg-slate-50 bg-opacity-50 border-[1px] border-slate-50 p-4 flex flex-col items-start justify-center'>
            <h1 className='text-2xl text-left w-full'>Upload File</h1>
            <p className='w-10/12 text-left text-sm my-2'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Praesentium qui ex, voluptatum necessitatibus dicta ipsa sed laudantium ipsum tenetur maiores.</p>

            <div className='w-full flex flex-col justify-start gap-1'>
                <label htmlFor="file" className='text-left text-sm font-semibold'>Storage Provider</label>
                <div className='bg-slate-400 bg-opacity-50 w-full flex gap-2 p-2 rounded-md'>
                    <div 
                        onClick={() => setProvider("anon-files")}
                        className={`flex justify-center items-center gap-4 grow p-2 rounded-md cursor-pointer ${anonFile_BG}`}
                        >
                        <img src="./anon.png" alt="anon files" className='w-6 h-6'/>
                        <p className='text-sm'>Anon </p>
                    </div>
                    <div 
                        onClick={() => setProvider("storj")}
                        className={`flex justify-center items-center gap-4 grow p-2 rounded-md cursor-pointer ${storJ_BG}`}
                        >
                        <img src="storj.png" alt="storj" className='w-6 h-6'/>
                        <p className='text-sm'>StorJ</p>
                    </div>
                </div>
            </div>
            <div className='upload_wrapper'>
                {/* <input type="file" onChange={(e) => handleFileChange(e)} /> */}
                <button onClick={() => handleUpload()}>Upload</button>
            </div>
            {/* <div className='button_container'>
                <button className='red' onClick={() => handleDelete()}>Delete</button>
                <button className='gray' onClick={() => handleLog()}>Log Data</button>
            </div> */}
        </div>
    )
}

export { Upload };