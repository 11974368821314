import React from "react";
import { useNavigate } from "react-router-dom";


const Footer = () => {
    const redirectTo = (url) => {
        window.location = url;
    }
    return (
        <div className="bg-slate-50 w-full p-2 absolute bottom-0 left-0">
            <span className="flex justify-center gap-2">
                <p onClick={() => redirectTo("https://www.akash11.com")} className="hover:text-blue-600 cursor-pointer">Akash Vaghela</p>
                •
                <p>2023</p>
            </span>
        </div>
    )
}

export { Footer }