import { SiMicrosoftonedrive } from "react-icons/si";

const Header = () => {
    return (
        <div className="w-full h-16 bg-slate-100 shadow-sm flex justify-start items-center px-4 gap-2">
            <SiMicrosoftonedrive className="text-2xl font-bold" />
            <span className="flex gap-1">
                <h1 className="font-bold">Z</h1>
                Drive
                </span>
        </div>
    )
}

export { Header }